import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['row', 'allSelector', 'exportButton', 'exportText', 'form']
  static values = {
    selectedIds: { type: Array, default: [] },
    totalCount: Number,
    allIds: { type: Array, default: [] },
    url: String,
    translations: Object
  }

  connect() {
    this.updateExportButtonVisibility()
  }

  toggleRow(event) {
    const rowId = event.target.value
    const isChecked = event.target.checked

    if (isChecked) {
      if (!this.selectedIdsValue.includes(rowId)) {
        this.selectedIdsValue = [...this.selectedIdsValue, rowId]
      }
    } else {
      this.selectedIdsValue = this.selectedIdsValue.filter(id => id !== rowId)
    }

    const allCheckboxesChecked = this.rowTargets.every(
      row => row.querySelector('input[type="checkbox"]').checked
    )
    this.allSelectorTarget.checked = allCheckboxesChecked

    this.updateExportButtonVisibility()
    this.updateForm()
  }

  toggleAll(event) {
    const checked = event.target.checked

    if (checked) {
      const newSelectedIds = this.allIdsValue
        .map(id => id.toString())
        .filter(id => !this.selectedIdsValue.includes(id))

      this.selectedIdsValue = [...this.selectedIdsValue, ...newSelectedIds]
    } else {
      this.selectedIdsValue = []
    }

    this.rowTargets.forEach(row => {
      const checkbox = row.querySelector('input[type="checkbox"]')
      checkbox.checked = checked
    })

    this.updateExportButtonVisibility()
    this.updateForm()
  }

  updateForm() {
    if (this.hasFormTarget) {
      let hiddenField = this.formTarget.querySelector('input[name="ids"]')
      if (hiddenField) {
        hiddenField.value = this.selectedIdsValue.join(',')
      } else {
        hiddenField = document.createElement('input')
        hiddenField.type = 'hidden'
        hiddenField.name = 'ids'
        hiddenField.value = this.selectedIdsValue.join(',')
        this.formTarget.appendChild(hiddenField)
      }
    }
  }

  updateExportButtonVisibility() {
    if (this.hasExportButtonTarget) {
      if (this.selectedIdsValue.length > 0) {
        this.exportButtonTarget.classList.remove('!hidden')

        if (this.hasExportTextTarget && this.hasTranslationsValue) {
          const count = this.selectedIdsValue.length
          const template = count === 1 ? this.translationsValue.one : this.translationsValue.other
          this.exportTextTarget.textContent = template.replace('%{count}', count)
        }

        const baseUrl =
          this.exportButtonTarget.dataset.batchSelectUrlValue || this.exportButtonTarget.href
        const url = new URL(baseUrl, window.location.origin)
        url.searchParams.delete('ids')
        this.selectedIdsValue.forEach(id => {
          url.searchParams.append('ids[]', id)
        })
        this.exportButtonTarget.href = url.toString()
      } else {
        this.exportButtonTarget.classList.add('!hidden')
        if (this.hasExportTextTarget && this.hasTranslationsValue) {
          this.exportTextTarget.textContent = this.translationsValue.zero
        }
      }
    }
  }

  clearSelection() {
    this.selectedIdsValue = []
    this.rowTargets.forEach(row => {
      const checkbox = row.querySelector('input[type="checkbox"]')
      checkbox.checked = false
    })
    this.allSelectorTarget.checked = false
    this.updateExportButtonVisibility()
    this.updateForm()
  }

  handlePagination() {
    this.rowTargets.forEach(row => {
      const checkbox = row.querySelector('input[type="checkbox"]')
      checkbox.checked = this.selectedIdsValue.includes(checkbox.value)
    })

    this.updateSelectAllState()
  }

  updateSelectAllState() {
    const allChecked = this.rowTargets.every(
      row => row.querySelector('input[type="checkbox"]').checked
    )
    this.allSelectorTarget.checked = allChecked
  }
}
